import React from "react";
import { FadeContainer, FadeText } from "../components/FadeText";
import { Page } from "../components/Page";

class LandingPage extends React.Component {
  state = {
    color: "transparent",
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ color: "white" });
    }, 7 * 1000);
  }

  render() {
    return (
      <Page className="parallax-wrapper landing-parallax-wrapper rubik">
        <FadeContainer
          delay={0.5}
          color={this.state.color}
          style={{
            zIndex: 1,
            position: "absolute",
            top: "35%", // 245,
            marginTop: 0,
            left: "calc(25% - 50px)",
            textAlign: "left",
            fontSize: "calc(2vw + 28px)",
            userSelect: "none",
            lineHeight: "calc(2.3vw + 36px)",
          }}
        >
          <FadeText>Tuukka</FadeText>
          <br />
          <FadeText>Miettinen</FadeText>
        </FadeContainer>
        <FadeContainer
          delay={1.75}
          color={this.state.color}
          style={{
            zIndex: 1,
            position: "absolute",
            top: "35%",
            marginTop: "calc(70px + 5vw)",
            left: "calc(25% - 50px)",
            textAlign: "left",
            fontWeight: 100,
            fontSize: "calc(1.05vw + 15px)",
          }}
        >
          <FadeText>Software Engineer</FadeText>
        </FadeContainer>
      </Page>
    );
  }
}

export default LandingPage;
