import React from "react";

const QuotePage = (props) => {
  return (
    <div style={{ color: "white", width: "100%", transformStyle: "inherit" }}>
      <div className="parallax-wrapper reference-parallax-wrapper">
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexFlow: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              minWidth: 300,
              width: "50vw",
              maxWidth: 600,
              textAlign: "justify",
              fontSize: "calc(12px + 0.75vmax)",
              marginRight: "calc(30vw - 100px)",
            }}
          >
            Tuukka is one of the most promising young software professionals that I have recently
            met. He has excellent capability to implement systems with React, Java, JavaScript,
            HTML5 and Python in a fast-paced work environment. His creative and productive approach
            enables him to deliver promised features in time exceeding the quality expectations. He
            is also very easy to approach with problems and questions.
          </div>
          <div
            className="rubik"
            style={{
              minWidth: 300,
              width: "50vw",
              maxWidth: 600,
              fontSize: "calc(16px + 0.75vmax)",
              textAlign: "right",
              marginTop: "15px",
              marginRight: "calc(30vw - 80px)",
            }}
          >
            ― Vesa Varimo
          </div>
          <div
            style={{
              minWidth: 300,
              width: "50vw",
              maxWidth: 600,
              fontSize: "calc(10px + 0.5vmax)",
              textAlign: "right",
              marginRight: "calc(30vw - 80px)",
            }}
          >
            CTO of Plus One Agency, Recommendation on LinkedIn
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotePage;
