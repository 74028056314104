import React from "react";

const EssayPage = (props) => {
  return (
    <div
      style={{ background: "white", zIndex: 1, width: "100%", paddingTop: 125, paddingBottom: 125 }}
    >
      <div className="container text-justify">
        <h2>Working at a start-up, building world class NLP services</h2>
        <h5 className="py-3">My adventures at Plus One Agency</h5>
        <p>
          My work at Plus One Agency started by developing the frontend of the P1Xi service. P1Xi is
          a tool to visualize customer feedback data from different perspectives. The development
          started by iterating a few prototypes with plain HTML and CSS, and after a good prototype
          was found, I started implementing it with React. As the frontend for P1Xi was finished and
          moved to maintenance, I was asked to develop a marketing website for it.
        </p>
        <p>
          The{" "}
          <a href="http://www.peopleintelligence.ai" target="_blank" rel="noopener noreferrer">
            marketing website
          </a>{" "}
          was developed by using HTML5, CSS and JavaScript, and deployed with Elastic Beanstalk. The
          website has a contact form which sends a notification email to the marketing team at Plus
          One Agency using a serverless solution for email contacting, which uses Amazon Web
          Service’s (AWS) Lambda-functions with the Simple Email Service (SES).
        </p>
        <p>
          After the P1Xi frontend and marketing sites were done, I was moved to develop the backend
          and database architecture on the project. The backend is coded using Python and Flask
          Server. It employs REST-architecture to ensure the maintainability and simplicity of the
          system. I have implemented new features, refactored code and fixed bugs in the code base.
          I’ve also optimized the database architecture to enable more complicated views of the user
          data.
        </p>
        <p>
          The whole architecture of P1Xi is using Docker containers to their fullest potential with
          AWS features like Elastic Compute Cloud (EC2), Elastic Container Service (ECS) and Elastic
          Container Registry (ECR). I have further containerized the system to be more modular and
          easier to maintain.
        </p>
        <p>
          Other work at Plus One Agency includes training the machine learning models and cleaning
          the data for those models. I analyze the customer data and identify relevant data points
          that can be utilized in the P1Xi product. The cleaning is done by using Python and Pandas.
          After cleaning the relevant data and configuring the P1Xi service, users can see their
          data, identify what is positive and what is negative in their services or products.
        </p>
        <p>
          The P1Xi service works by utilizing a lot of customer feedback. It analyzes and gives an
          idea of how the data looks, what the customers are talking about, what they like and what
          they don’t like. For example, a parking space problem can easily be noticed as P1Xi will
          see that many people have mentioned something related to parking. These mentions will be
          assigned a sentiment based on the trained models, and it’s easy to see which locations
          have the best, and which locations have to worst parking spaces, based on natural language
          user feedback.
        </p>
      </div>
    </div>
  );
};

export default EssayPage;
