import React from "react";

const Experience = (props) => {
  return (
    <>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%" }}>
        <div
          style={{
            height: props.imageSize,
            minHeight: props.imageSize,
            width: props.imageSize + 10,
            minWidth: props.imageSize + 10,
            pointerEvents: "none",
            paddingRight: 10,
            marginRight: 75 - props.imageSize,
          }}
        >
          <img src={props.image} alt="icon" style={{ height: "100%", borderRadius: 5 }} />
        </div>
        <div className="card border-0">
          <h5 className="card-title">{props.title}</h5>
          <h6 className="card-subtitle mb-2 text-muted">{props.date}</h6>
          <p className="card-text">{props.desc}</p>
        </div>
      </div>
      <div style={{ marginLeft: props.imageSize + 10, marginTop: 10 }}>{props.children}</div>
    </>
  );
};

export default Experience;
