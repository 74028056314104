import React from "react";
import "./styles/index.css";
import "./styles/animations.css";
import LandingPage from "./pages/LandingPage";
import ExperiencePage from "./pages/ExperiencePage";
import QuotePage from "./pages/QuotePage";
import EssayPage from "./pages/EssayPage";
import WorkInProgressPage from "./pages/WorkInProgressPage";

const App = () => {
  return (
    <>
      <LandingPage />
      <ExperiencePage />
      <QuotePage />
      <EssayPage />
      <WorkInProgressPage />
    </>
  );
};

export default App;
