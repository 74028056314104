import React from "react";
import { Page } from "../components/Page";
import { Logo } from "../components/Logo";
import Experience from "../components/Experience";
import puolustusvoimat from "../media/pv.jpg";
import plusone from "../media/plusone.png";
import aalto from "../media/aalto.png";
import gurufield from "../media/gurufield.png";

const ExperiencePage = (props) => {
  return (
    <Page className="pb-5 px-2" style={{ background: "white", width: "100%", zIndex: 1 }}>
      <div
        style={{ height: 140, background: "transparent", position: "relative", userSelect: "none" }}
      >
        <Logo />
      </div>

      <div className="d-block d-sm-block d-md-block d-lg-none" style={{ height: 50 }} />

      <div className="container px-0">
        <h2 className="rubik pb-2">Experience</h2>
        <Experience
          image={puolustusvoimat}
          imageSize={75}
          title={"Finnish Defense Forces • Software Developer, Team Leader"}
          date={"Jul 2017 – Mar 2018"}
          desc={
            "During my time as a constrict, I developed content and held training sessions for the war simulator, Virtual Battlespace 3, which the Finnish Defense Forces uses for training its troops. I was also the leader of the developer team in our garrison."
          }
        />
        <hr />
        <Experience
          image={plusone}
          imageSize={65}
          title={"Plus One Agency • Software Engineer"}
          date={"Mar 2018 – Apr 2020"}
          desc={
            "I started as a front-end engineer, and as the project aged I was moved into working on the backend, and currently I develop and upkeep the whole stack of Plus One Agency’s P1xi product."
          }
        />
        <hr />
        <Experience
          image={gurufield}
          imageSize={65}
          title={"Gurufield • Senior Software Engineer"}
          date={"Aug 2019 – Present"}
          desc={
            "Gurufield is a cloud-based solution for health, safety, environment and quality management, providing the best tools for the entire process. I work at Gurufield as a full-stack engineer. My responsibilities include developing and maintaining the software stack, managing the helpdesk operations of the company, and carrying out various projects and tasks for customers."
          }
        />
        <hr />
        <h2 className="rubik pt-3 pb-2">Education</h2>
        <Experience
          image={aalto}
          imageSize={75}
          title={"Aalto University • Computer Science"}
          date={"2016 – 2023"}
        >
          <p>
            I graduated with Honors from Aalto University with a Master's degree in Computer
            Science.
          </p>
          <p>
            My bachelor's programme was in Science and Technology, with the Major being Computer
            Science and Minor being in Data Science. My Master's programme was in Computer,
            Communication and Information Sciences with the degree focus being on Web Technologies,
            Applications and Science.
          </p>
          <p>
            My Thesis was titled: <br />
            Improving discoverability of content on a cloud-native C# solution
          </p>
          <p>
            The thesis was focused on creating search functionality for text content in a relational
            database without using external services. The search solution was later taken into
            production use and has been widely praised by customers.
          </p>
          <p>Some of the notable courses I’ve taken:</p>
          <ul>
            <li>Cloud Software and Systems</li>
            <li>Web Software Development</li>
            <li>Big Data Platforms</li>
            <li>Semantic Web</li>
            <li>Operating Systems</li>
            <li>Computer Networks</li>
            <li>Information Security</li>
            <li>Security Engineering</li>
            <li>Network Security</li>
            <li>Cryptography</li>
            <li>Information Visualization</li>
            <li>Human-Computer Interaction</li>
          </ul>
        </Experience>
      </div>
    </Page>
  );
};

export default ExperiencePage;
