import React from "react";
import profile from "../media/wip.png";

const WorkInProgressPage = (props) => {
  return (
    <div
      style={{
        background: "black",
        height: 500,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}
    >
      <img
        src={profile}
        alt="work in progress"
        className="mx-auto"
        style={{
          height: 320,
          pointerEvents: "none",
        }}
      />
    </div>
  );
};

export default WorkInProgressPage;
