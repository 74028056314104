import React from "react";
import profile from "../media/profile.jpg";

export function Logo(props) {
  return (
    <img
      src={profile}
      alt="logo"
      className="mx-auto"
      style={{
        ...props.style,
        border: "solid white 15px",
        top: -150,
        left: "calc(50% - 160px)",
        position: "absolute",
        height: 320,
        pointerEvents: "none",
        borderRadius: "50%",
        minHeight: 250,
      }}
    />
  );
}
