import React from "react";

export const FadeContainer = (props) => {
  let elements = React.Children.toArray(props.children);
  for (let i = 0; i < elements.length; i++) {
    // elements[i] = React.cloneElement(elements[i], { delay: ((i+1)/5)+props.delay });
    elements[i] = React.cloneElement(elements[i], { delay: props.delay });
  }

  return (
    <p
      style={{
        ...props.style,
        color: props.color,
      }}
    >
      {elements}
    </p>
  );
};

export const FadeText = (props) => {
  return (
    <span
      className="blur-in"
      style={{
        ...props.style,
        fontSize: props.size,
        animationDelay: props.delay + "s",
      }}
    >
      {props.children}
    </span>
  );
};
